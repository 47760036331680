.hero {
	margin-top: 3rem;
	align-items: center;
}

.hi-text {
	/* color: var(--accent-color); */
	font-size: 30px;
	font-weight: 800;
}

.profile {
	cursor: pointer;
	width: 300px;
	height: 300px;
	min-width: 300px;
	min-height: 300px;
	border-radius: 50%;
	background-image: url("/public/images/profile-headshot.jpg");
	background-size: cover;
	background-position-x: center;
	background-repeat: no-repeat;
}

.details-container {
	width: 400px;
	text-align: left;
}

.details-container > span {
	text-align: left;
}

@media screen and (max-width: 800px) {
	.details-container {
		width: 100%;
		align-items: center;
	}

	.details-container > span {
		text-align: center;
		width: 300px;
	}

	.hero {
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
		height: 70vh;
	}
}

.social-icon {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
