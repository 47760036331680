.animate-blur-rise {
  /* animation-delay: 1s; */
  animation: 0.5s linear 0s 1 blurRiseIn;
}

.animate-blur-rise-longer {
  animation: 1s linear 0s 1 blurRiseIn;
}

@keyframes blurRiseIn {
  0% {
    opacity: 0;
    transform: translateY(40px);
    filter: blur(1rem);
  }

  33% {
    opacity: 0;
    transform: translateY(40px);
    filter: blur(1rem);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}
