.gallery-image {
	height: 25vw;
	width: 25vw;

	min-width: 150px;
	min-height: 150px;

	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.react-photo-gallery--gallery > div {
	justify-content: center;
}

.carousel {
	animation: 1s fade-in;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
