button.btn {
  cursor: pointer;
  padding: 16px;
  border-radius: 5px;
  color: var(--button-text);
  background-color: var(--primary-button-color);
  border: 0.5px solid var(--primary-background);
  font-size: 12px;
  text-decoration: none;
  transition: 0.2s;
}

button.btn:hover {
  opacity: 0.85;
}
