@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("./styles/animations.css");
@import url("./styles/buttons.css");

:root {
  --primary-color: #222831;
  --secondary-color: #393e46;
  --tertiary-color: #eeeeee;
  /* --accent-color: #ffd369; */
  --accent-color: var(--secondary-color);

  --primary-background: white;
  --secondary-background: var(--tertiary-color);
  /* --tertiary-color: black; */

  --primary-text: var(--primary-color);
  --secondary-text: var(--secondary-color);
  --plain-text: var(--primary-color);
  --contrast-text: var(--tertiary-color);

  --light-grey: var(--tertiary-color);
  --primary-button-color: var(--primary-color);
  --link-color: var(--secondary-color);
  --button-text: var(--tertiary-color);
}

[data-theme="dark"] {
  --primary-background: #222831;
  /* --primary-background: #041c32;Í */
  --secondary-background: #393e46;
  /* --secondary-background: #04293a; */
  --tertiary-color: #064663;

  --accent-color: #ffd369;
  --primary-text: white;
  --secondary-text: white;
  --plain-text: white;
  --contrast-text: black;

  --button-text: black;
  --primary-button-color: var(--accent-color);
  --link-color: var(--accent-color);
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
  height: 100%;
}
