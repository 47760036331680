.container {
  display: flex;
  position: relative;
  width: 100vw;
  justify-content: center;
}

.container.picture-small {
  height: 300px;
}

.container.picture-medium {
  height: 500px;
}

.container.picture-large {
  height: 800px;
}

.container-align-bottom {
  align-items: end;
}

.container-align-center {
  align-items: center;
}

.container-align-top {
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
}

.text-container {
  padding: 16px;
  border-radius: 20px;
  background-color: var(--primary-background);
  position: absolute;
}

.container-align-top > .text-container {
  margin-top: 3rem;
}
.container-align-bottom > .text-container {
  margin-bottom: 3rem;
}

.headline {
  color: var(--accent-color);
}
