.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-content: center;
	background-color: var(--primary-background);
	transition: 0.2s;
	min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--primary-text);
}

p,
div,
span,
a {
	color: var(--plain-text);
}

.accent {
	color: var(--accent-color);
}

/* Utility Classes */
.d-flex {
	display: flex;
}
.flex-row {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

a {
	color: var(--link-color);
	text-decoration: none;
	transition: 0.2s;
}
a:hover {
	opacity: 0.6;
}

.section {
	max-width: 600px;
	margin: auto;
	padding: 0 1rem;
}

button.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	/* text-decoration: underline; */
	display: inline;
	margin: 0;
	padding: 0;
}

.row-to-column {
	flex-direction: row;
}

@media screen and (max-width: 800px) {
	.row-to-column {
		flex-direction: column;
	}
}

.section {
	margin: 1rem auto;
}
