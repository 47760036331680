.container {
  align-items: center;
  margin: auto;
  gap: 1rem;
}
.image {
  width: 250px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.title-text {
  font-size: 20px;
  margin: 0;
}

.work-text {
  text-align: left;
  width: 250px;
}

hr {
  width: 100%;
  max-width: 300px;
  display: none;
}

.duration {
  font-size: 13px;
}
.description {
  font-size: 12px;
}

@media screen and (max-width: 800px) {
  .work-text {
    text-align: center;
  }

  hr {
    display: block;
  }

  .description {
    display: none;
  }
}
