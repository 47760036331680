.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    width: fit-content;
    border: 1px solid black;
}

.toolbarItem {
    cursor: pointer;
    padding: 12px;
    min-width: 80px;
    font-size: 10px;
    background-color: white;
    outline: 1px solid black;
}

.toolbarItem:hover {
    background-color: black;
    color: white;
    transition: 0.5s;
}